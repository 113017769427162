<template>
    <v-container grid-list-md fluid>
        <v-alert type="info" prominent text border="left">
            <div class="d-flex flex-column subtitle-2 secondary--text">
                <span class="font-weight-bold">Existing Alphacruncher Account</span>
                Your email address exists in our system but it does not belong to any registered Nuvolos user. To sign up, you can upgrade your Alpacruncher
                account to Nuvolos account.
            </div>
        </v-alert>
        <v-card-actions>
            <v-btn :loading="upgradingToNuvolos" elevation="0" color="secondary" outlined block @click="upgradeToNuvolosAccount()">
                <span>Upgrade to Nuvolos account</span>
            </v-btn>
        </v-card-actions>
    </v-container>
</template>
<script>
export default {
    name: 'UpgradeToNuvolos',
    props: {
        componentSpecificProps: {
            type: Object,
            default: function () {
                return {
                    userEmail: ''
                }
            }
        },
        recommended: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            upgradingToNuvolos: false
        }
    },
    methods: {
        warning(event) {
            event.returnValue = ''
        },
        upgradeToNuvolosAccount: function () {
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Upgrading your account, please remain on this page...',
                snackBarTimeout: 5000,
                snackBarIcon: 'info'
            })
            window.addEventListener('beforeunload', this.warning, true)
            this.upgradingToNuvolos = true
            this.$axios
                .post('/users/register_from_midpoint/auth0', { email: this.$props.componentSpecificProps.userEmail })
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Account successfully upgdated...',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    location.reload()
                })
                .catch(error => {
                    if (!error.response || !error.response.data || error.response.data.code !== 'already_registering') {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'User upgrade has failed, try again later...',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    }
                })
                .finally(() => {
                    this.upgradingToNuvolos = false
                    window.removeEventListener('beforeunload', this.warning, true)
                })
        }
    }
}
</script>
